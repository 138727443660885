.inline {
    display: inline-block;
}

.flex {
    display: flex;
}

.flex-around {
    justify-content: space-evenly;
}
.flex-stretched {
    justify-content: space-between;
}
.flex-grow {
    flex-grow: 1;
}
.centered {
    align-content: center;
    justify-content: space-around;
}

.anchor {
    right:anchor(left);
}
.fixed {
    display: inline-block;
    width: 60%;
}

.hide {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.block {
    display: block;
}
.bottom {
    bottom: 0;
}
.top {
    top:0;
}
.floating {
    position: absolute;
    /* position: relative; */
}

.base {
    
    position: relative;
}
.left {
    left: 0;
}

.inline-margin {
    /* padding: 0 15px; */
    padding-inline: 15px;
}

.font-200 {
    font-size: 24px;
}
.x-75 {
    width: 75%;
}

.x-30 {
    width: 30%;
}
.x-90 {
    width:90%;
}
.padding {
    height: 75px;
}

.height-padding {
    /* padding: 10px 0px; */
    padding-block: 10px;
}

.height-spacing {
    margin-block: calc(var(--margin) / 2);
}
.inline-spacing {
    margin-inline: var(--margin);
}