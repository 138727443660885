.primary-button {
    border: var(--font-color) solid 1px;
    border-radius: 5px;
    padding: 5px;

}

.btn-standard {
    margin-inline: 5px;
    padding: 5px;
}

.btn-sticky {
    box-shadow: 
    5px 5px 2px rgb(58, 58, 58),
    -5px 5px 2px rgb(58, 58, 58);
    text-align: center;
    width: 50px;
    height: 50px;
    position:fixed;
    margin: 0 0 20px 20px;
}

.btn-dialog {
    padding-top: 5px;

}
.btn-close {
    color:var(--negative-color);
    font-size: 20px;
    border-radius: 50%;
    text-shadow: -1px -1px 0 var(--font-color), 1px -1px 0 var(--font-color), -1px 1px 0 var(--font-color), 1px 1px 0 var(--font-color);
    
}

.btn-form {
    outline: solid var(--font-color) 1px;

}