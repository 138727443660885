.responsive-image-container {
    position: relative;
    width: 100%;
    max-width: 100vw;
    margin: 0 auto; /* Center the container */
    overflow: hidden; /* Hide overflow */
  }
  
  .responsive-image {
    min-width: 100%;
    min-height: auto;
    max-height: 200%;
    /* aspect-ratio: 1; */
    /* object-fit:; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  