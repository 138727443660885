#page{
    container-type: inline-size;
    container-name: page;
    display: grid;
    grid-template-columns: auto 4fr;
    /* transition: all .3s ease-in-out; */
}

.expandable {
    /* animation: pulse 1s infinite alternate;
    perspective: 100px; */
    
}
.links {
    text-decoration: underline;
    /* text-shadow: var(--secondary-color) 1px 1px; */
    cursor: pointer;
}
article {
    margin:0 20px 100px;
    word-wrap: normal;
    h1 {
        font-size: 2rem;
        margin-bottom: 30px;
        margin-top: 30px;
    }
    p {
        text-align: justify;
    }
    h6{
        margin:50px 0 50px 0;
        /* font-size: 1.25rem; */
    }
    ul {
        background-color: var(--secondary-color);
        
        margin-block: 20px;
        padding:10px;
        padding-left: 40px;
        /* list-style-type: circle; */

        li {
            display: list-item;
            vertical-align: top;
            position: relative;
            margin-bottom: 40px;
        }

        li::before{
            /* content: "*"; */
            height: 10px;
            width: 40px;
            display: inline-block;
            position: absolute;
            /* top:0; */
            left:-20px;
            /* font-size: rem; */
            color: white;
            fill: white;
        }        
    }

    .img-container {
        height: 300px;
        width: auto;
        overflow: hidden;
        img {
            object-fit: contain;
            width: 100%;
        }
    }

}
.collapsable-x *{
    width: 100%;
    transition: all .1s ease-in-out;
    overflow: hidden;
}

& .collapsed-x {
    width: 20px;
    ul * {
        overflow: hidden;
        width: 0;
    }
}
.collapse-button-x {
    width: 20px;
}

.collapsable-y *{
    height: 100%;
    transition: all .3s ease-in-out;
    overflow: hidden;
}

& .collapsed-y * {
    height: 0px;
    margin-block: 0px;
    padding: 0px;
    ul * {
        overflow: hidden;
        height: 0;
    }
}

.collapse-button-y {
    height: 20px;
}

.bento {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap:30px;

    > * {
        border-radius: 15px;
        padding: 20px;
        background: linear-gradient(108deg, var(--tertiary-color) 24%, var(--secondary-color));
    }

    .bento-3 {
        grid-column: span 3;
    }

    .bento-2 {
        grid-column: span 2;
    }
}

@media (max-width:900px){
    #page {
        grid-template-columns: 1fr;
    }
    #sidebar {
        display: none;
    }
    article{
        margin-inline: 0px;
        .img-container {
        height: 300px;
        img{
            object-fit: cover;
            height: 100%;
            object-position: left;
        }
    }
    ul {
        padding-left: 10px;
    }
    }
    .bento {
        grid-template-columns: 1fr;
        gap:10px 0px;
    }
    .bento-small{
        grid-template-columns: repeat(2,1fr);
    }
}

@container page (max-width:700px) {
    
}

@media (prefers-color-scheme:light) {
    #page {
        /* background-color: rgb(225, 229, 233); */
        /* color: rgb(44, 44, 44); */
    }
    .bento {    
        > * {
            background: var(--accent-gradient)
        }
    }
}