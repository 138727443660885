@media (prefers-color-scheme:light){
    :root{
        
        --primary-color: #EEEEEE;
        --primary-dark-color: #727274;
        --secondary-color: #E8D5C4;
        --tertiary-color: #FFF1DC;
        --font-color: #080808;
        --accent-font-color:#eee9e9;
        --highlight-font-color:rgb(160, 59, 12);
        --affirmative-color: #3ae67cd3;
        --negative-color: #c74949;
        --warning-color: #e79639;
        --action-color: #2105f3;
        --active-color: #457cb6;
        --progress-color:rgb(16, 146, 4);
        --margin: 20px;
        
        --shadow: 1px 1px 2px rgb(27, 28, 29)  ;
        
        --accent-gradient: linear-gradient(108deg, var(--tertiary-color) 24%, var(--secondary-color));
        --action-gradient: linear-gradient(110deg, var(--action-color), var(--tertiary-color));
        --negative-gradient: linear-gradient(110deg, var(--negative-color), var(--highlight-font-color));
    }
    
}
    
@media (prefers-color-scheme:dark){
    :root{
        --primary-color:#161b22;
        --primary-light-color: #8195c4;
        --primary-dark-color: #3d3d5f;
        --secondary-color: #1F2328;
        --tertiary-color: #142b55;
        --font-color: #c7c4c4;
        --accent-font-color: #c7c4c4;
        --highlight-font-color:rgb(155, 115, 7);
        --affirmative-color: #3a9633d3;
        --negative-color: #521313;
        --warning-color: #e2c29e;
        --action-color: #2b2557;
        --active-color: #293542;
        --progress-color:rgb(16, 146, 4);
        --margin: 20px;
        
        --shadow: 1px 1px 2px rgb(48, 54, 61);

        --accent-gradient: linear-gradient(108deg, var(--tertiary-color) 24%, var(--action-color));
        --action-gradient: linear-gradient(110deg, var(--action-color), var(--tertiary-color));
        --negative-gradient: linear-gradient(110deg, var(--negative-color), var(--highlight-font-color));
        
    }
          
}