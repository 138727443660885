.primary {
    background-color: var(--primary-color);
    color: var(--font-color);
}

.secondary {
    background-color: var(--secondary-color);
    color: var(--font-color);
}

.tertiary {
    background-color: var(--tertiary-color);
    color: var(--font-color);
}

.warning {
    background-color: var(--warning-color);
    color: var(--primary-color);
}

.negative {
    /* background-color: var(--negative-color); */
    background-image: var(--negative-gradient);
    color:var(--font-color)
}

.action {
    /* background-color: var(--action-color); */
    background-image: var(--accent-gradient);
    color:var(--accent-font-color)
}

.active {
    background-image: var(--action-gradient);
}
.form-input {
    background-color: var(--primary-color);
    color: var(--font-color);
    margin: 5px;
    display: block;
    padding:5px;
}

.highlight-text {
    color: var(--warning-color);
}