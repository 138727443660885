.rating {
    box-sizing: border-box;
    /* position: relative; */
    /* z-index: 2; */
    /* width: 40px; */
    aspect-ratio: 1.25;
    height: 80%;
    border-radius: 15%;
    background-color: transparent;
    border: 2px solid var(--action-color); 
    overflow: hidden;
    /* clip-path: circle(40px at center);  */
    margin-inline: 2px;
  }

  .level{
    /* position: absolute; */
    border-radius: 0;
    left: 0px;
    height: 110%;
    /* background-image: linear-gradient(to right, 
    #800303 0%,
    #d67609 35%,  
    #055f05 70%,
    #0aee0a 100%
    ); */
}

.container {
    /* position: absolute; */

    background-color: transparent;
    /* width: 200px; */
    aspect-ratio: 1/5;
    height:1.5rem;
    display: flex;
    /* z-index: 1; */
    justify-content: space-evenly;
    align-items: center;

    /* clip-path: circle(20px at 25px); */
}

.progress {
  overflow:hidden;
  border-radius: 20px;
  width: 250px;
  height: 1.5rem;
  background-color: var(--action-color);
  /* z-index: -1; */
}
/* .mastery::before{
  content: url(./star.svg);
  
}
.mastery svg {
  stroke: white;
} */
.mastery {
  /* outline: 1px solid gold; */
  /* border: 2px solid gold;  */
  /* clip-path: url(./star.svg); */
  /* clip-path: path('M 0 200 L 0,75 A 5,5 0,0,1 150,75 L 200 200 z'); */
  /* clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  ); */
  
}
.testing {
  position: relative;
  height: 200px;
  aspect-ratio: 1;
  border-radius: 50%;

}
  .testing::before,
  .testing::after {
    content: "";
    position: absolute;
    border: 5px solid white;
    border-radius: 50%;
  }

  .testing::before{
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    border-left:none;
    border-bottom: none;
  }

  .testing::after{
    bottom: -5px;
    right:-5px;
    width: 100%;
    height: 100%;
    border-right: none;
    border-top:none;
  }

  .circle-container {
    width: 50px; /* Adjust as needed */
    height: 50px; /* Adjust as needed */
  }
  
  .circle-background {
    fill: none;
    stroke: var(--primary-color); /* Color of the circle background */
    stroke-width: 6; /* Thickness of the circle */
  }
  
  .circle-progress {
    fill: none;
    /* stroke: url(#progressGradient); */
    stroke: var(--progress-color);
    stroke-width: 6; /* Thickness of the circle */
    stroke-linecap: round;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    transition: stroke-dashoffset 0.5s;
  }
  