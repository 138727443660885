* {
    margin: 0;
    padding: 0;
    font-size: 20px;
    border-radius: 5px;
}

#root {
    background-color: var(--primary-color);
    color: var(--font-color);
}

#content {
    margin: 20px;
}

a {
    /* all:unset; */
    cursor: pointer;
}

a, button, input, select, option, textarea {
    all:unset;
    border-radius: 5px;
    /* height: fit-content; */
    /* width: 30px; */
    
}

/* textarea {
    display: inline-block;
    rows: "4";
} */
input:focus, select:focus, option:focus, textarea:focus {
    background-color: var(--active-color);
    
}

label {
    padding-inline: 5px;
}

input[type="checkbox"]{
    border: 1px solid var(--font-color);
    border-radius: 50%;
    display: inline-block;   
    height: .75rem;
    width: .75rem;
    flex-shrink: 0;
}

input:checked {
    background-color: var(--affirmative-color);
}

select, option {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    padding:0 10px;
}

select::-ms-expand {
    display: none;
}

option {
    background-color: var(--primary-color);
    padding:10px;
}

html {
    margin:0;
}

body {
    background-color: var(--primary-color);
    /* display: flex; */
    /* flex-direction: column; */
}

button {
    appearance:none;
    display: inline-block;
    /* margin:5px */
}

ul {
    list-style-type: none;
    /* display: inline-block; */
    /* margin-block: 0; */

}

li {
    /* all:unset; */
    display: block;
    margin-top: 10px;
}

input, select {
    /* all:unset;   */
    padding:5px 5px;
    margin: 2px 5px;
}

form {
    margin: 5px;
    padding: 5px;
}

textarea {
    /* all:unset; */
    width:100%;
}

textarea:invalid {
    border: red;
    background-color: var(--negative-color);
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}