/* Dialog container styling */
dialog {    
    margin-inline:auto;
    transition: 5s;
    opacity: 0;
    background-color: var(--accent-color);
    color: var(--font-color);
}

dialog[open] {
    background-color: var(--primary-color);
    top:10%;
    width: 90%;
    max-height:70%;
    opacity: 1;
    animation: fadeIn .25s normal forwards;   
}

dialog.fade[open] {
    animation: fadeOut .25s normal forwards;
}

dialog::backdrop {
    top:0;
    left:0;
    transition: 2s;
    background-color: rgba(50, 75, 100, .8);
}
