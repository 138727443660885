
.wrap {
    word-wrap: break-word;
    overflow-wrap: break-word;
}


/* .inline {
    display: inline-block;
}

.flex {
    display: flex;
}

.flex-around {
    justify-content: space-evenly;
}
.flex-stretched {
    justify-content: space-between;
}
.flex-grow {
    flex-grow: 1;
}
.centered {
    align-content: center;
    justify-content: space-around;
} */

.highlight {
    border-bottom: 3px solid var(--secondary-color);

}

.bordered {
    border: 2px solid;
    padding:5px;
    margin: 5px;
}




.rounded {
    border-radius: 20px;
    width: 60px;
    text-align: center;
}

.right {
    margin-left: auto;
    margin-right: 20px;
}

.text-centered {
    text-align: center;
}

.shadow {
    box-shadow: var(--shadow);
}

.hidden {
    visibility: hidden;
}

.scroll {
    overflow-y: scroll;
}

.sticky {
    overflow: auto;
    position:sticky;
    top:20px;
}